"use client";

import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Button, ButtonLink } from "@/components/ui/button";
import Search from "@/components/icons/Search";
import ChevronFill from "@/components/icons/ChevronFill";
import { cn, strapiMediaAlt, strapiMediaUrl } from "@/lib/utils";
import MobileMenu from "@/components/general/MobileMenu";
import { Hamburger } from "@/components/ui/hamburger";
import { usePathname, useRouter } from "next/navigation";
import { cva } from "class-variance-authority";
import useWindowSize from "@/hooks/useWindowSize";
import { useStore } from "@/store";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import Image from "next/image";

const activeMenuVariants = cva("absolute left-[-18px] top-1/2 h-3 w-3 -translate-y-1/2 rounded-full", {
  variants: {
    variant: {
      "Over ons": "bg-nsc-orange",
      Europa: "bg-nsc-blue",
      Speerpunt: "bg-nsc-yellow",
      Nieuws: "bg-nsc-mint",
      "Jong Sociaal Contract": "bg-nsc-ice-blue",
      Agenda: "bg-nsc-mint",
      download: "",
      Video: "",
      "Goed bestuur": "bg-nsc-orange",
      Bestaanszekerheid: "bg-nsc-yellow",
      Wonen: "bg-nsc-dark-brown",
      Migratie: "bg-nsc-europe-blue",
      "Internationale stabiliteit": "bg-green",
      "Gezonde leefomgeving": "bg-light-green",
      "Digitale leefomgeving": "bg-cyan",
      Gezondheidszorg: "bg-red",
      Mensen: "bg-nsc-orange",
      Pensioenen: "bg-nsc-orange",
      Gemeenteraadsverkiezingen: "bg-nsc-dark-brown",
    },
  },
  defaultVariants: {
    variant: "Over ons",
  },
});

const HeaderJSC = ({ menuData, tagsData, user }: any) => {
  const pathname = usePathname();
  const router = useRouter();
  const [openSearchDialog, setOpenSearchDialog] = useState(false);
  const [search, setSearch] = useState("");
  const [transparent, setTransparent] = useState(false);
  const [hover, setHover] = useState(false);
  const [hideTopHeader, setHideTopHeader] = useState(false);
  const [openMegaMenu, setOpenMegaMenu] = useState(false);
  const [open, setOpen] = useState(false);
  const [megaOffset, setMegaOffset] = useState(0);
  const menuRef = useRef<HTMLUListElement>(null);
  const { width } = useWindowSize();
  const { setTagsData } = useStore();

  useEffect(() => {
    setTagsData(tagsData);
  }, [tagsData, setTagsData]);

  useEffect(() => {
    setOpen(false);
    setOpenMegaMenu(false);
  }, [pathname]);

  useEffect(() => {
    if (!menuRef.current) return;
    setMegaOffset(menuRef.current.getBoundingClientRect().left);

    const handleResize = () => {
      if (!menuRef.current) return;
      setMegaOffset(menuRef.current.getBoundingClientRect().left);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const currentUrl = pathname === "/" ? "/home" : pathname;

  return (
    <motion.header
      onMouseEnter={() => width > 768 && setHover(true)}
      onMouseLeave={() => width > 768 && setHover(false)}
      initial={false}
      animate={{
        y: hideTopHeader ? (menuData?.topBar?.enabled ? -80 : -40) : 0,
      }}
      transition={{
        duration: 0.5,
        ease: [0.16, 1, 0.3, 1],
      }}
      className={cn("fixed left-0 top-0 z-[200] h-auto w-full", "bg-white")}
    >
      {/* Top Bar */}
      {menuData?.topBar?.enabled &&
        (menuData?.topBar?.Url ? (
          <Link
            href={menuData?.topBar?.Url}
            style={{ background: menuData.topBar.backgroundColor }}
            className="hidden h-[40px] w-full bg-[rgba(255,255,255,0.2)] md:block"
          >
            <div className="container h-full">
              <div className="mx-auto flex h-full w-fit items-center justify-center gap-6">
                <p className="font-oswald text-base uppercase text-white">{menuData.topBar.text}</p>
              </div>
            </div>
          </Link>
        ) : (
          <div
            style={{ background: menuData.topBar.backgroundColor }}
            className="hidden h-[40px] w-full bg-[rgba(255,255,255,0.2)] md:block"
          >
            <div className="container h-full">
              <div className="mx-auto flex h-full w-fit items-center justify-center gap-6">
                <p className="font-oswald text-base uppercase text-white">{menuData.topBar.text}</p>
              </div>
            </div>
          </div>
        ))}

      {/* Top header */}
      <div className={cn("hidden h-[40px] w-full md:block", "bg-jsc-sky-blue")}>
        <div className="container">
          <div className="ml-auto flex w-fit items-center gap-6">
            <Dialog open={openSearchDialog} onOpenChange={setOpenSearchDialog}>
              <DialogTrigger asChild>
                <Button variant="ghost" className="px-0 text-white">
                  <Search color="white" className="mr-3" /> Zoeken
                </Button>
              </DialogTrigger>
              <DialogContent className="max-w-xl">
                <DialogHeader className="mb-6 flex flex-col gap-2">
                  <DialogTitle>Zoeken</DialogTitle>
                  <DialogDescription>Vul hieronder je zoekopdracht in</DialogDescription>
                </DialogHeader>

                <form
                  onSubmit={(e: any) => {
                    e.preventDefault();
                    if (search === "") return;
                    router.push(`
                        /zoeken?filter=${search === "" ? "all" : search}
                      `);
                    setOpenSearchDialog(false);
                  }}
                  className="flex items-center"
                >
                  <div className="grid flex-1 gap-2">
                    <Label htmlFor="link" className="sr-only">
                      Zoekopdracht
                    </Label>
                    <Input
                      onChange={(e: any) => {
                        setSearch(e.target.value);
                      }}
                      id="search"
                      type="text"
                      placeholder="Zoekopdracht"
                    />
                  </div>
                  <Button type="submit" size="lg" variant="secondary">
                    <Search color="white" className="mr-0" />
                    <span className="sr-only">Zoeken</span>
                  </Button>
                </form>
              </DialogContent>
            </Dialog>

            <ButtonLink variant="ghost" className="text-white" href="/contact">
              Contact
            </ButtonLink>
            <ButtonLink
              variant="ghost"
              className="text-white"
              href="https://partijnieuwsociaalcontract.nl/mijn-account/inloggen"
              target="_blank"
            >
              Inloggen
            </ButtonLink>
          </div>
        </div>
      </div>

      {/* Main header */}
      <div
        className="container my-4 grid h-fit grid-cols-[auto,1fr,auto] items-center justify-between md:my-[17px] lg:gap-x-2">
        <Link aria-label="" href="/">
          <span className="sr-only">Home</span>
          <div
            className={cn(
              "relative",
              "left-[30px] top-[0px] bg-white h-[45px] w-[59px] -rotate-[10deg] scale-[1.4] rounded-md md:left-[35px] md:top-[0px] md:scale-[2.1] md:shadow-lg"
            )}
            color="white"
          >
            <Image
              src={strapiMediaUrl(menuData.logo)}
              alt={strapiMediaAlt(menuData.logo)}
              priority
              fill={true}
              className={cn(
                "rotate-[6deg]"
              )}
            />
          </div>
        </Link>
        <nav className="hidden justify-center lg:flex">
          <motion.ul layout layoutRoot ref={menuRef} className={cn("flex list-none gap-8 xl:gap-10", "text-black")}>
            {menuData?.menu?.items.map((item: any, index: number) => (
              <MenuItem
                item={item}
                setOpenMegaMenu={setOpenMegaMenu}
                megaOffset={megaOffset}
                openMegaMenu={openMegaMenu}
                pathname={currentUrl}
                key={`${item.url}-${index}`}
              />
            ))}
          </motion.ul>
        </nav>
        <div className="hidden lg:flex">
          <ButtonLink variant="default" size="default" href="https://partijnieuwsociaalcontract.nl/lid-worden"
                      target="_blank">
            Meedoen
            <ChevronFill color="black" className="transition-transform group-hover:translate-x-1" />
          </ButtonLink>
        </div>
        <Hamburger open={open} setOpen={setOpen} />
      </div>

      {/* Mobile menu */}
      <MobileMenu open={open} menuData={menuData} />
    </motion.header>
  );
};

const MenuItem = ({ item, setOpenMegaMenu, megaOffset, openMegaMenu, pathname }: any) => {
  const [hover, setHover] = useState(false);

  return item.type === "wrapper_element" ? (
    <NestedItem
      megaOffset={megaOffset}
      openMegaMenu={openMegaMenu}
      setOpenMegaMenu={setOpenMegaMenu}
      key={item.url}
      item={item}
      pathname={pathname}
    />
  ) : (
    <li
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      key={item.url}
      className="leading-0 relative font-oswald text-base font-bold uppercase"
    >
      <AnimatePresence>
        {(hover || item.url.includes(pathname)) && item?.tag && (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            style={{ y: "-50%" }}
            exit={{ opacity: 0, scale: 0, transition: { delay: 0.1 } }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3, ease: [0.16, 1, 0.3, 1] }}
            className={activeMenuVariants({ variant: item?.tag as any })}
          />
        )}
      </AnimatePresence>
      <Link href={item.url}>{item.title}</Link>
    </li>
  );
};

const NestedItem = ({
  item,
  setOpenMegaMenu,
  megaOffset,
  openMegaMenu,
  pathname,
}: {
  item: any;
  setOpenMegaMenu: React.Dispatch<React.SetStateAction<boolean>>;
  megaOffset: number;
  openMegaMenu: boolean;
  pathname: string;
}) => {
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);
  useEffect(() => {
    if (openMegaMenu !== item.id) {
      setOpen(false);
    }
  }, [openMegaMenu, item.id]);

  return (
    <li
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      key={item.url}
      className="leading-0 font-oswald text-base font-bold uppercase"
    >
      <button
        onClick={() => {
          setOpen(!open);
          setOpenMegaMenu(open ? false : item.id);
        }}
        className="relative flex items-center uppercase"
      >
        <AnimatePresence>
          {hover && item?.tag && (
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              style={{ y: "-50%" }}
              exit={{ opacity: 0, scale: 0, transition: { delay: 0.1 } }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.3, ease: [0.16, 1, 0.3, 1] }}
              className={activeMenuVariants({ variant: item.tag as any })}
            />
          )}
        </AnimatePresence>
        {item.title}{" "}
        <ChevronFill
          className={cn("ml-2 transition-transform", open ? "!rotate-0" : "!rotate-180")}
          color="black"
          direction="down"
        />
      </button>
      <MegaMenu tag={item.tag} megaOffset={megaOffset} open={open} items={item.nestedItems} />
    </li>
  );
};

const MegaMenu = ({ items, open, megaOffset, tag }: { items: any; open: boolean; megaOffset: number; tag: string }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: [0.16, 1, 0.3, 1] }}
      animate={{ opacity: open ? 1 : 0 }}
      style={{ pointerEvents: open ? "all" : "none" }}
      className={cn("absolute left-0 -z-20 mt-[27px] h-auto w-screen pb-[52px] pt-[37px]", "bg-white shadow-sm")}
    >
      <div
        style={{ transform: `translateX(${megaOffset}px)` }}
        className="grid grid-cols-[auto,1fr,auto] gap-x-2 lg:gap-x-12"
      >
        <ul className={cn("flex list-none flex-col gap-8 text-white", "text-black")}>
          {items &&
            items.length > 0 &&
            items.map((item: any, index: number) => {
              return (
                <MenuItem
                  item={{
                    ...item,
                    tag,
                  }}
                  megaOffset={megaOffset}
                  key={`${item.url}-${index}`}
                />
              );
            })}
        </ul>
      </div>
    </motion.div>
  );
};

export default HeaderJSC;
